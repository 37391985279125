<template>
  <div>
    <lp-datatable
      ref="referralPayoutTableRef"
      title="Referral Payout Request"
      store-module="payout"
      :extra-parameter="extraParam"
      store-data-endpoint="getAdminPayoutRecordsFiltered"
      store-getter-endpoint="getPayoutRecordFilteredList"
      pagination-state-key="payoutRecordFilteredList"
      :features="loadedConfig.datatableFeatures"
      :load-system-constant="true"
      default-search-field="transaction_invoice_no"
      default-sort-field="created_at"
      :search-options-dropdown="loadedConfig.searchOptsDropdown"
      :conf="loadedConfig"
      @status-filter-btn-clicked="statusFilterBtnClicked"
      @actionBtn-clicked="actionBtnClicked"
      @aditBtn-clicked="editBtnClicked"
      @viewBtn-clicked="viewBtnClicked"
      @deleteBtn-clicked="deleteBtnClicked"
      @extraBtn-clicked="extraBtnClicked"
    />

  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import axios from 'axios'
import Vue from 'vue'
import Config from '@/views/administration/referralPayout/formConfig'
import humaniseMixin from '@/common/humanise.mixin'
import LpDatatable from '@/leanpay-components/LPDataTable.vue'

import LpBaseFileUpload from '@/leanpay-components/LPBaseFileUpload.vue'

export default {
  components: {
    LpDatatable, ValidationObserver, ValidationProvider, LpBaseFileUpload, vSelect,
  },
  mixins: [humaniseMixin],
  data() {
    return {
      currentRowData: {},
      cardTitle: 'Payout',
      dataGridEndpoint: [],
      module: 'payout',
      confirmCheckbox: false,

      // DataGridSetting
      filter: '',
      searchTerm: '',
      currentPage: 1,
      isBusy: true,
      selectedPerPage: 50,
      sortBy: 'created_at',
      sortDesc: true,

      proofForm: {},

      extraParam: {
        transaction_status: [
          'PENDING_APPROVAL',
        ],
      },

      record_status: 1, // active only
      searchField: 'transaction_invoice_no',

      searchFieldOpts: [
        { value: 'transaction_invoice_no', text: 'Invoice No' },
      ],
    }
  },
  computed: {
    transactionStatusOpts() {
      return [
        { value: 1, text: 'PENDING' },
        { value: 2, text: 'COMPLETED' },
      ]
    },
    loadedConfig() {
      if (Config) {
        return Config
      }
      return {}
    },
    tableConfig() {
      if (Config) {
        return Config.tableData()
      }
      return {}
    },
    tableSettings() {
      const list = this.$store.getters[`${this.module}/getPaginationInfo`]
      // const { list } = dummyRes.data
      const perPage = this.selectedPerPage
      let listLength
      if (list !== null) {
        listLength = this.GridData.recordsTotal
      } else {
        listLength = 100
      }
      return {
        rows: listLength,
        perPage,
        refresh: true,
        filter: false,
        pagination: true,
        search: true,
      }
    },
    TableHeader() {
      const x = this.tableConfig.tableHeader
      return x.filter(y => y.visible)
    },
    TableActions() {
      const x = this.tableConfig.tableAction
      return x.filter(y => y.enable)
    },
    sortObj() {
      const obj = {
        parameter_name: this.sortBy,
        sort_type: this.sortDesc ? 'desc' : 'asc',
      }
      return obj
    },
    GridData() {
      const list = this.$store.getters[`${this.module}/getPayoutRecordList`]
      if (list !== null) {
        return list
      }
      return []
    },
    GridSettings() {
      const list = this.$store.getters[`${this.module}/getPaginationInfo`]

      console.log('---->', list)
      if (list !== null) {
        return {
          draw: list.draw,
          recordsTotal: list.record_total,
          next_page: list.next_page,
          next_page_length: list.next_page_length,
          next_page_start: list.next_page_start,
          previous_page_length: list.previous_page_length,
          previous_page_start: list.previous_page_start,
          previouse_page: list.previouse_page,
          recordsFiltered: list.record_filtered,
        }
      }
      return []
    },
  },
  async mounted() {
    // this.isBusy = false
    const breadcrumbUpdatePayload = [
      { title: 'Payout' },
    ]
    await this.$store.dispatch('breadcrumbs/setBreadcrumb', breadcrumbUpdatePayload)

    // this.initDataGrid()
  },
  methods: {
    statusFilterBtnClicked(e) {
      if (e === 'pendingApprovalReferralPayoutFilter') {
        this.extraParam.transaction_status = ['PENDING_APPROVAL']
        this.$refs.referralPayoutTableRef.initDataGrid()
      }

      console.log('statusFilterBtnClicked', e)
    },

    uploadFiles() {
      const formData = new FormData()

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.files.length; i++) {
        // formData.append('file', this.files[i])
        formData.append('files', this.files[i])
      }
      this.$store.dispatch('mediaStore/uploadMerchantImageCdn', formData).then(res => {
        if (res.data.response_code === 2000) {
          console.log('data', res.data.data)
          this.filesUrl = res.data.data
        }
      })
    },
    onUpdate() {
      console.log(this.proofForm)
      const payload = {
        // ...this.proofForm,
        id: this.proofForm.id,
        transaction_status: this.proofForm.transaction_status,
        image_path: this.proofForm.image_path,
      }

      this.$store.dispatch('payout/updateAdminPayoutRecords', payload).then(res => {
        if (res.data.response_code === 2000) {
          this.initDataGrid()
          this.cancelForm()
        }
      })
    },
    cancelForm() {
      this.$refs.payoutProofAdmin.hide()
    },
    processForm(field, $event) {
      if (field === 'image_path') {
        // eslint-disable-next-line prefer-destructuring
        console.log('proofForm imagePath', $event)
        this.proofForm[field] = $event
      } else {
        this.proofForm[field] = $event
      }
    },
    async actionBtnClicked(e) {
      console.log(e.rowData.id)
      if (e.actionName === 'approvePayout') {
        const defaultParam = {
          title: 'Confirmation',
          confirmButtonText: 'Confirm',
          showCancelButton: true,
          html: 'Are you sure you want to approve this payout?',
          allowOutsideClick: false,
          allowEscapeKey: false,
          customClass: {
            container: 'leanx-swal',
            // header: 'bg-danger',
            confirmButton: 'btn btn-sm btn-primary mr-1',
            cancelButton: 'btn btn-sm btn-secondary',
            input: 'form-control',
          },
        }
        Vue.swal(defaultParam).then(result => {
          if (result.isConfirmed) {
            const payload = {
              _id: e.rowData.id,
            }
            this.$store.dispatch('affiliate/approveAffiliatePayout', payload).then(res => {
              this.$refs.referralPayoutTableRef.initDataGrid()
            })
          }
        })
      }
      console.log(e)
    },
    deleteBtnClicked(e) {
      console.log(e)
    },
    viewBtnClicked(e) {
      console.log(e)
    },
    editBtnClicked(e) {
      console.log(e)
    },
    extraBtnClicked(e) {
      console.log(e)
    },
    pagination(e) {
      if (this.tableSettings.search) {
        const payload = {
          start: (e - 1) * this.tableSettings.perPage,
          length: this.tableSettings.perPage,
          sort: this.sortObj,
          search: this.searchTerm,
        }
        this.isBusy = true
        this.$store.dispatch(this.dataGridEndpoint, payload).then(() => {
          this.isBusy = false
        })
      }
    },
    sortingChanged(ctx) {
      this.sortDesc = !this.sortDesc
      this.sortBy = ctx.sortBy
      const payload = {
        start: 0,
        length: this.tableSettings.perPage,
        sort: this.sortObj,
        search: this.searchTerm,
      }
      this.isBusy = true
      this.$store.dispatch(this.dataGridEndpoint, payload).then(() => {
        this.currentPage = 1
        this.isBusy = false
      })
    },
    searchGrid(vuexEndpoint) {
      const payload = {
        start: 0,
        length: this.tableSettings.perPage,
        sort: this.sortObj,
        search: this.searchTerm,
      }
      this.isBusy = true
      this.$store.dispatch(vuexEndpoint, payload).then(() => {
        this.currentPage = 1
        this.isBusy = false
      })
    },
    initDataGrid() {
      console.log('plans')
      this.isBusy = true
      const payload = {
        limit: this.tableSettings.perPage,
        skip: 0,
        search: {
          search_enable: false,
          search_key: '',
          search_column: '',
          search_replace_word_enable: true,
          search_word_replace: ' ',
          search_word_replace_to: '_',
        },
        sort: this.sortObj,
      }
      this.isBusy = true
      this.$store.dispatch(`${this.module}/getAdminPayoutRecords`, payload).then(() => {
        this.currentPage = 1
        this.isBusy = false

        this.searchTerm = ''
      })
    },
  },
}
</script>
