const Config = {
  datatableFeatures: {
    extraButtons: [
      // {
      //   buttonLabel: 'Download Template',
      //   buttonRef: 'downloadTemplateBtn',
      //   variant: 'info',
      // },
    ],
    tableStatusFilterButton: [
      {
        buttonLabel: 'Pending Approval',
        buttonRef: 'pendingApprovalReferralPayoutFilter',
        active: true,
      },
    ],
    buttons: {
      createButton: false,
      createButtonLabel: 'Create New Payout',
      createButtonRoute: 'administration-payout-new',
      refresh: true,
    },

    tableActionField: false,
    datepicker: false,
    showDTDetails: true,
    perPageDropdown: true,
    searchField: false,
    filter: false,
    pagination: true,
    search: true,
  },
  searchOptsDropdown: [
    { value: 'account_id', text: 'Account ID' },
    { value: 'transaction_invoice_no', text: 'Invoice No' },
    { value: 'recipient_name', text: 'Recipient Name' },
    { value: 'payout_account_number', text: 'Recipient Account No' },
    { value: 'value', text: 'Amount' },
    { value: 'transaction_status', text: 'TXN Status' },
  ],
  tableData() {
    return {
      tableAction: [
        // CUSTOM
        {
          enable: true, param: 'id', btnLabel: 'Approve Payout', action: 'custom', btnIcon: '', btnOnly: false, variant: 'secondary', size: 'sm', iconSize: '12', actionParam: 'approvePayout', disableCondition: 'status:COMPLETED',
        },
        // {
        //   enable: true, param: 'id', btnLabel: 'A', action: 'custom', btnIcon: '', btnOnly: false, variant: 'warning', size: 'sm', iconSize: '12', actionParam: 'openA',
        // },
        // {
        //   enable: true, param: 'id', btnLabel: 'Update Status', action: 'custom', btnIcon: '', btnOnly: false, variant: 'info', size: 'sm', iconSize: '12', actionParam: 'openUpdatePayoutStatus',
        // },

        // DEFAULT
        {
          enable: false, param: 'id', btnLabel: 'View', action: 'view', btnIcon: 'SearchIcon', btnOnly: true, variant: 'primary', size: 'sm', iconSize: '12',
        },
        {
          enable: false, param: 'id', btnLabel: 'Edit', action: 'edit', btnIcon: 'Edit2Icon', btnOnly: true, variant: 'info', size: 'sm', iconSize: '12',
        },
        {
          enable: false, param: 'id', btnLabel: 'Remove', action: 'remove', btnIcon: 'Trash2Icon', btnOnly: true, variant: 'danger', size: 'sm', iconSize: '12',
        },
      ],
      tableHeader: [
        {
          key: 'account_id', label: 'Account ID', sortable: true, visible: true, type: 'string',
        },
        {
          key: 'transaction_invoice_no', label: 'Invoice No', sortable: true, visible: true, type: 'string',
        },
        {
          key: 'recipient_name', label: 'Paid to (Name)', sortable: true, visible: true, type: 'string',
        },
        {
          key: 'payout_account_number', label: 'Paid to (Account)', sortable: true, visible: true, type: 'string',
        },
        {
          key: 'value', label: 'Amount', sortable: true, visible: true, type: 'monetary',
        },
        {
          key: 'transaction_status', label: 'Transaction Status', sortable: true, visible: true, type: 'string',
        },
        {
          key: 'created_at', label: 'Created At', sortable: true, visible: true, type: 'date',
        },
        {
          key: 'actions',
          label: 'Action',
          class: 'text-right',
          visible: true,
        },
      ],
    }
  },
}

export default Config
